<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="q-gutter-sm" v-if="props.data.tipe == 'accept'">
        <q-select
          outlined
          emit-value
          map-options
          label="PIC Request"
          :options="optRnd"
          v-model="selRnd"
        ></q-select>
        <div>
          Apa anda yakin ingin menerima Development Request untuk Item
          {{ props.data.detail.name }} dan menjadikan
          <b>
            {{
              optRnd.find((x) => {
                return x.value == selRnd;
              }).label
            }}
          </b>
          sebagai PIC?
        </div>
      </q-card-section>
      <q-card-section
        v-if="props.data.tipe == 'reject'"
        class="column q-gutter-sm"
      >
        <div>
          Apa anda yakin ingin menolak Development Request untuk Item
          {{ props.data.detail.name }}?
        </div>
        <q-input outlined label="Alasan Penolakan" v-model="reason"></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat color="primary" label="Tidak" @click="onCancelClick" />
        <q-btn
          unelevated
          color="primary"
          label="Ya"
          :disable="
            (props.data.tipe == 'reject' && reason == '') || selRnd == 0
          "
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";

export default {
  props: ["data"],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();

    const $http = inject("$http");
    const $q = useQuasar();

    onMounted(async () => {
      if (!props.data.detail.name) {
        props.data.detail.name = props.data.detail.item_name;
      }

      await getRnd();
    });

    let getRnd = async () => {
      let resp = await $http.get(`/request/rnd`);
      optRnd.value = resp.data;
      selRnd.value = props.data.user.id;
    };

    let optRnd = ref([{ label: "-", value: 0 }]);
    let selRnd = ref(0);

    let reason = ref("");

    let onOKClick = async () => {
      let payload = {
        request_status: props.data.detail.request_status,
        cur_user_id: props.data.user.id,
        pic: selRnd.value,
        id: props.data.detail.id,
        declined_note: reason.value,
        req_type: props.data.req_type,
      };
      payload.is_development = 0;
      if (props.data.req_type == "dev") {
        payload.is_development = 1;
      }

      payload.request_status_baru = "PROCESS";
      if (props.data.tipe == "reject") {
        payload.request_status_baru = "DECLINED";
      }

      try {
        await $http.post(`/request/update-status`, payload);
        $q.notify({ message: "Request Berhasil Diproses", color: "positive" });
      } catch (error) {
        $q.notify({ message: "Request Gagal Diproses", color: "negative" });
      }
      onDialogOK();
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      props,
      reason,
      optRnd,
      selRnd,
    };
  },
};
</script>

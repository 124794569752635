<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row items-center justify-between">
        <a class="text-h6">Pernyataan Hasil Penelitian</a>
        <q-btn flat dense icon="close" @click="onCancelClick"></q-btn>
      </q-card-section>
      <q-card-section>
        Dengan ini saya menyatakan bahwa Hasil Penelitian terhadap
        {{ props.detail.name }}
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          class="full-width q-mb-sm"
          color="negative"
          label="Gagal"
          @click="onOKClick('FAILED')"
        />
        <q-btn
          unelevated
          class="full-width"
          color="positive"
          label="Berhasil"
          @click="onOKClick('SUCCESS')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";

export default {
  props: ["detail", "req_type", "user"],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();

    const $http = inject("$http");
    const $q = useQuasar();

    onMounted(() => {
      if (!props.detail.name) {
        props.detail.name = props.detail.item_name;
      }
    });

    let onOKClick = async (val) => {
      let payload = {
        request_status: props.detail.request_status,
        type: props.detail.type,
        request_status_baru: val,
        cur_user_id: props.user.id,
        id: props.detail.id,
        req_type: props.req_type,
      };
      payload.is_development = 0;
      if (props.req_type == "dev") {
        payload.is_development = 1;
      }

      try {
        await $http.post(`/request/update-status`, payload);
        $q.notify({
          message: "Request Status Berhasil Diubah",
          color: "positive",
        });
      } catch (error) {
        $q.notify({
          message: "Request Status Gagal Diubah",
          color: "negative",
        });
      }
      onDialogOK(val);
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      props,
    };
  },
};
</script>

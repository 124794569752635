<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row items-center justify-between">
        <a class="text-h6">Finish Observe</a>
      </q-card-section>
      <q-card-section>
        <span>
          Apa Anda yakin ingin mengakhiri masa observasi pada item
          {{ props.detail.name }} ?
        </span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Tidak"
          color="primary"
          @click="onCancelClick"
        ></q-btn>
        <q-btn unelevated color="primary" label="Ya" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, ref } from "vue";

export default {
  props: ["detail", "req_type", "user"],

  emits: [...useDialogPluginComponent.emits],

  setup(props) {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();

    const $http = inject("$http");
    const $q = useQuasar();

    onMounted(() => {
      if (!props.detail.name) {
        props.detail.name = props.detail.item_name;
      }
    });

    let onOKClick = async () => {
      let payload = {
        po_qty: props.detail.po_qty,
        cur_user_id: props.user.id,
        id: props.detail.id,
        req_type: props.req_type,
      };

      payload.is_development = 1;
      if (props.req_type == "test") {
        payload.is_development = 0;
      }

      try {
        await $http.post(`/request/done-observed`, payload);
        $q.notify({
          message: "Sales Status Berhasil Diubah",
          color: "positive",
        });
      } catch (error) {
        $q.notify({ message: "Sales Status Gagal Diubah", color: "negative" });
      }
      onDialogOK();
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      props,
    };
  },
};
</script>
